import React, { useState, useRef, useEffect } from 'react';
import '../App.css';
import SupportedHardware from '../assets/img/supported_hardware.webp';
import PingIcon from '../assets/img/ping.webp';
import ChatIcon from '../assets/img/chat.png';
import HardwareIcon from '../assets/img/noHardware.png';
import GeoRestrictions from '../assets/img/geo.webp';
import Review from '../components/Review';
import YoutubeEmbed from "../components/YoutubeEmbed";
import HappyGamer from '../assets/img/happy-gamer.webp';
import Warzone2 from '../assets/img/warzone2.webp';
import Warzone from '../assets/img/warzone.webp';
import ModernWarfare3 from '../assets/img/modern.webp';
import ModernWarfare from '../assets/img/mw.webp';
import BlackOps6 from '../assets/img/blackops6.webp';
import Vanguard from '../assets/img/vanguard.webp';
import Step1 from '../assets/img/step-1.webp';
import Step2 from '../assets/img/step-2.webp';
import Step3 from '../assets/img/step-3.webp';
import DiscordLogo from '../assets/img/discord-mascot.png';
import FAQ from '../components/faq';
import PricingCard from '../components/PricingCard';
import KaidaxProfile from '../assets/img/kaidax-profile.webp';
import VicricusProfile from '../assets/img/vicricus-profile.webp';
import Sw4TProfile from '../assets/img/sw4t-profile.webp';
import SupportImage from '../assets/img/support.webp';
import Cart from '../assets/img/grocery-store.png';
import Features from '../assets/img/star.png';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import { useLocation } from 'react-router-dom';

function Home() {

  const navigate = useNavigate();
  const { isLoggedIn } = useAuth(); 
  const location = useLocation();

  const onButtonClick = () => {
    if (isLoggedIn) {
      navigate('/account');
    } else {
      navigate('/sign-up');
    }
  };
  
  const faqs = [
    { question: 'Is It Possible To Get Banned?', answer: 'The answer is very simple. No you won’t get banned using a VPN on Call of Duty as it’s not breaking any terms of the game.' },
    { question: 'Am I Guaranteed Easy Lobbies?', answer: 'Our VPN will boost the chances of getting better lobbies than without using a VPN. We can guarantee that you will like the game more and have a lot more fun!' },
    { question: 'Can I Use it On My Playstation or Xbox?', answer: 'Our DNS VPN works for every platform. You don\'t need to have any hardwares in order to use our VPN.' },
    { question: 'Can I Try It Before Purchasing?', answer: 'Yes we offer now 24 hours for free, so that you can test our product.' },
    { question: 'Will It Harm My Computer?', answer: 'No! You don\'t need to install anything in order to use our product.' },
  ];

  useEffect(() => {
    const saveReferralCode = () => {
      const queryParams = new URLSearchParams(location.search);
      const ref = queryParams.get('ref');
      if (ref) {
        localStorage.setItem('referralCode', ref);
      }
    };

    saveReferralCode();
  }, [location]);
  

  const featuresRef = useRef(null);
  const featuresRef2 = useRef(null);

  const scrollToFeatures = () => {
    featuresRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  const scrollToFeatures2 = () => {
    featuresRef2.current.scrollIntoView({ behavior: 'smooth' });
  };

  const handleClick = () => {
    window.location.href = 'https://discord.gg/sweatvpn';
  };

  const pricingOptions = [
    {
      title: 'Free Trial',
      subtitle: 'Try before purchasing',
      price: 'Free',
      features: ['Over 30 locations', 'No hardware required', 'Compatible with all platforms', "No download required", "24/7 support"],
      buttonText: 'Try Free Trial',
      onButtonClick: () => {
        window.location.href = '/sign-up';
      },
    },
    {
      title: '1 Month',
      subtitle: 'Monthly Subscription',
      price: '$9.99/month',
      features: ['Over 30 locations', 'No hardware required', 'Compatible with all platforms', "No download required", "24/7 support"],
      buttonText: 'Choose Monthly',
      onButtonClick: () => {
        window.location.href = '/sign-up';
      },
    },
    {
      title: 'Year',
      price: '$49.99/year',
      subtitle: 'Most Popular | Save 50%',
      features: ['Over 30 locations', 'No hardware required', 'Compatible with all platforms', "No download required", "24/7 support"],
      buttonText: 'Choose Year',
      onButtonClick: () => {
        window.location.href = '/sign-up';
      },
    },
  ];

  const [reviews] = useState([
    {
      img: KaidaxProfile,
      text: "This VPN allows me to work a 9-5 and still rank in the top 100 leaderboards for wins and kills. It's helped boost my TikTok following to over 12k+ followers and YouTube to over 4k+ subscribers.",
      name: "Kaidax",
      role: "Content Creator"
    },
    {
      img: Sw4TProfile,
      text: "Easily the best Warzone 4 VPN out there and since i had the amazing opportunity of a partnership with them my TikTok following and my views have skyrocketed. I've used the VPN for almost a year now and would recommend it 100%!",
      name: "Sw4Tヅ",
      role: "TikTok Content Creator"
    },
    {
      img: VicricusProfile,
      text: "SweatVPN ensures i get relaxed lobbies and can focus on engaging with my Twitch chat simultaneously. Without SweatVPN, i'd probably be stressed out (with grey hair) dealing with overly sweaty lobbies!",
      name: "Vicricus",
      role: "Twitch Streamer"
    },

  ]);

  const [currentReviewIndex, setCurrentReviewIndex] = useState(0);


  const nextReview = () => {
    setCurrentReviewIndex((prevIndex) => (prevIndex + 1) % reviews.length);
  };

  const prevReview = () => {
    setCurrentReviewIndex((prevIndex) => (prevIndex - 1 + reviews.length) % reviews.length);
  };

  const currentReview = reviews[currentReviewIndex];


  return (
    <div className="home">
      <header className="home-header">

        <h1 className = "home-header-text">
          The Best <span className="highlight">Gaming VPN</span> For Warzone
        </h1>

        <p className="subtitle">
          Unlock the best Warzone 4 VPN on Console & PC today! Make SBMM a thing of the past, taking your
          <br />
          place on the leaderboards. Requires no hardware!</p>
        <p className="support-text">SWEATDNS SUPPORTS</p>
        <div className="supported-hardware">
          <img src={SupportedHardware} alt="Supported Hardware" />
        </div>
        <div className="button-container" style={{fontWeight: 'bold'}}>
          <a href="#buynow" onClick={scrollToFeatures2} className="button buy-now">
            <img src={Cart} alt="Buy now" style={{ width: '20px', marginRight: '5px', verticalAlign: '-2px' }} />Buy Now
          </a>
          <a href="#features" onClick={scrollToFeatures} className="button features">
            <img src={Features} alt="Features" style={{ width: '20px', marginRight: '5px', verticalAlign: '-2px' }} />Features
          </a>    
        </div>

      </header>
      <div ref={featuresRef} className="features-container" >
        <h1 className="features-header"  > SweatDNS <span className="highlight">Features</span></h1>
        <p className="features-subtitle" >SweatDNS is the <span className="highlight"> best VPN for Warzone 4</span> that requires no hardware! </p>
        <div className="features-grid">
          <div className="feature-item" >
            <img src={PingIcon} alt="Zero Ping" />
            <h2>Zero Lag</h2>
            <p>Access low-latency servers strategically located for optimal Warzone 4 performance.</p>
          </div>
          <div className="feature-item"  >
            <img src={GeoRestrictions} alt="Zero Restrictions" />
            <h2>Zero Restrictions</h2>
            <p>Connect to our extensive network of servers worldwide for a seamless gaming experience.</p>
          </div>
          <div className="feature-item" >
            <img src={PingIcon} alt="Zero Ping" />
            <h2>30+ Connections</h2>
            <p>Overcome geo-restrictions effortlessly, connect to over 50+ locations worldwide.</p>
          </div>
        </div>
        <div className="second-grid" >
          <div className="feature-item" >
            <img src={HardwareIcon} alt="Zero Ping" />
            <h2>No Hardware Needed!</h2>
            <p>Follow our easy setup guide to enjoy the benefits of the VPN.</p>
          </div>
          <div className="feature-item" >
            <img src={ChatIcon} alt="Zero Ping" />
            <h2>24/7 Help & Support</h2>
            <p>Receive worldwide 24/7 help & support via the Discord Server and Website at no extra cost.</p>
          </div>
        </div>
      </div>
      <div className="reviews-container">
        <Review review={currentReview} />
        <div className="nav-buttons-container">
          <button className="nav-button prev" onClick={prevReview}>&lt;</button>
          <button className="nav-button next" onClick={nextReview}>&gt;</button>
        </div>
      </div>
      <div className="features-container">
        <div className="features-grid-2">
          <div className="feature-item-2">
            <h1><span className="feature-item-2-title">Why Choose</span> SweatDNS?</h1>
            <p>When you download the best Warzone 4 VPN, we prioritize performance, security, and user experience. Our low-latency servers and global network ensure that you get the most out of every Warzone 4 session. Join our community of satisfied gamers who are experiencing the SweatVPN difference every day.</p>
            <div className="benefits-container">
              <div className="benefit-item">
                <span className="checkmark">✓</span> IMPROVED PING
              </div>
              <div className="benefit-item">
                <span className="checkmark">✓</span> ELIMINATE SBMM
              </div>
              <div className="benefit-item">
                <span className="checkmark">✓</span> NO HARDWARE NEEDED
              </div>
              <div className="benefit-item">
                <span className="checkmark">✓</span> RELIABLE CONNECTION
              </div>
            </div>
          </div>
          <div className='feature-item-2'>
            <YoutubeEmbed embedId="Zbn9-5MIcvk" />
          </div>
        </div>
        <div className="features-grid-2">
          <div className="feature-item-2 your-video-container">
          </div>
          <div className="feature-item-2 your-second-text-container">
          </div>
          <div className='feature-item-2'>
            <img className="happy-gamer-img" src={HappyGamer} alt="Supported Hardware" />
          </div>
          <div className="feature-item-2">
            <h1><span className="feature-item-2-title">Enjoy Gaming</span> once Again!</h1>
            <p>With the best Warzone 4 VPN you’ll become the envy of your friends. Wielding the power to host bot lobbies. Everyone will want to get in your squad for a night of dominating the competition on Warzone 4. Whether you’re wiping squads on Urzikstan or destroying teams on Resurgence, SweatVPN will keep you connected and the squad in high spirits.</p>
            <div className="benefits-container">
              <div className="benefit-item">
                <span className="checkmark">✓</span> EASY LOBBIES
              </div>
              <div className="benefit-item">
                <span className="checkmark">✓</span> HIGHER KILL GAMES
              </div>
              <div className="benefit-item">
                <span className="checkmark">✓</span> IMPROVE YOUR KD
              </div>
              <div className="benefit-item">
                <span className="checkmark">✓</span> RACK UP MORE WINS
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="compatible-grid">
        <h1>SweatDNS <span className="highlight">Works With</span></h1>
        <p>These are our supported games. We will be adding new games to the list once they are out!</p>
      </div>
      <div className="image-item">
          <img src={BlackOps6} alt="Blackops6" style={{height: '150px'}}/>
        </div>
      <div className="image-grid">
        <div className="image-item">
          <img src={Warzone2} alt="Warzone 2" />
        </div>
        <div className="image-item">
          <img src={ModernWarfare3} alt="Modern Warfare 3" />
        </div>
        <div className="image-item">
          <img src={Warzone} alt="Warzone" />
        </div>
        <div className="image-item">
          <img src={ModernWarfare} alt="Modern Warfare II" />
        </div>
        <div className="image-item">
          <img src={Vanguard} alt="Vanguard" />
        </div>
      </div>
      <div className="features-container">
        <div className="how-header">
          <h1><span className="highlight">How </span>It Works?</h1>
          <p className="how-subtitle">The purpose of SweatDNS VPN is that you don't need to own any hardware such as a PC. You can simply connect to any location you want from your phone.</p>
        </div>

        <div className="how-to-grid">
          <div className="how-to-item">
            <img src={Step1} alt="Download SweatVPN" />
            <h3>Login</h3>
            <p>Login your account and go to the dashboard page.</p>
          </div>
          <div className="how-to-item">
            <img src={Step2} alt="Watch the Tutorial" />
            <h3>Select your location</h3>
            <p>Click on any country you want to connect with.</p>
          </div>
          <div className="how-to-item">
            <img src={Step3} alt="It's Time to Dominate" />
            <h3>Change your DNS servers</h3>
            <p>Get connected and drop into Warzone 4, it's time to experience the SweatDNS advantage!</p>
          </div>
        </div>
      </div>

      <div className="support-container">
        <div className="support-text">
          <h1><span className="highlight">24/7 Help </span> & Support</h1>
          <p>Our team is available 24/7 to help you with any issues you may encounter. Whether you need help with setup, or have a question about your account, we are here to help.</p>
          <button className="discord-button"  onClick={handleClick} style={{fontWeight: 'bold', display: 'inline-flex', alignItems: 'center', justifyContent: 'center' }}>
            <img src={DiscordLogo} alt="Discord" style={{ height: '16px', width: '20px', marginRight: '5px' }} />
            Join Our Discord
          </button>
        </div>
        <div className="support-image">
          <img src={SupportImage} alt="Support" />
        </div>
      </div>
      <div className="features-container">
        <div className="try-before">
          <h1><span className="highlight">Try Before </span> You Buy</h1>
          <p>Don't just take our word for it, and there's no need to make any commitments.</p>
          <br />
          <p>Experience SweatDNS for yourself with a completely free 3-days trial and see why everyone is talking about it.</p>
          <div className="button-container-trial">
            <button onClick={onButtonClick} className="button buy-now" style={{fontWeight: 'bold'}}>Try Free Trial</button>
            <span className="button-subtitle">No credit card or payments needed!</span>
          </div>
        </div>
      </div>
      <div className="faq-container">
        <div className="faq-text">
          <h1>Frequently Asked <span className="highlight">Questions</span></h1>
          <p>Here are some of the most common questions we get asked about SweatDNS.</p>
        </div>
      </div>
      <div>
        <FAQ faqs={faqs} />
      </div>
      <div className="pricing-container">
        <div className="pricing-header">
          <h1><span className="highlight">Simple </span>Pricing</h1>
          <div className="pricing-subtitle">
            <p>Choose the plan that works best for you.</p>
          </div>
        </div>

        <div ref={featuresRef2} className="pricing-flex-container">
          {pricingOptions.map((option, index) => (
            <PricingCard key={index} {...option} />
          ))}
        </div>
      </div>
    </div>
  );
}


export default Home;
